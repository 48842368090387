import axios from 'axios';

//获取课包列表
export async function GetQueryCourseClassFicationList(queryinfo){
    var res = await axios.get('CourseClassFication/v1/CourseClassFicationQuery', {params: queryinfo})
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      return null;
    });
    return res;
  }



//获取所有课程分类
export async function GetCourseClassFications(){
    var res = await axios.get('CourseClassFication/v1/CourseClassFications')
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      return null;
    });
    return res;
  }
  
  //获取所有课程分类以及课程
  export async function GetCourseClassFicationAndCourse(){
    var res = await axios.get('CourseClassFication/v1/CourseClassFicationAndCourse')
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      return null;
    });
    return res;
  }

//新建课包
export async function PostCourseClassFicationInfo(courseclassficationinfo){
    var res = await axios.post('CourseClassFication/v1/CourseClassFication', courseclassficationinfo)
    .then(function(response){
      return response.data;
    })
    .catch(function (error){
      console.log(error);
      return null;
    });
    return res;
  }
  
  
  
  //修改课包信息
  export async function PutCourseClassFicationInfo(courseclassficationinfo){
    var res = await axios.put('CourseClassFication/v1/CourseClassFication', courseclassficationinfo)
    .then(function(response){
      return response.data;
    })
    .catch(function (error){
      console.log(error);
      return null;
    });
    return res;
  }
  
  //删除课包信息
  export async function DeleteCourseClassFication(id){
    var res = await axios.delete('CourseClassFication/v1/CourseClassFication',{ params: { id:id}})
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      return null;
    });
    return res;
  }
  