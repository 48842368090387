<template>
    <div class="list-div">
        <el-card class="el-card-mainview">
            <el-row :gutter="30">
                <el-col :span="8">
                    <el-input placeholder="请输入课包名称" v-model="QueryInfo.Name" clearable @clear="initQueryCourseClassFicationList"> 
                        <el-button slot="append" icon="el-icon-search"  @click="initQueryCourseClassFicationList"></el-button>
                    </el-input>
                </el-col>
                <el-col :span="4">
                    <el-button type="primary" @click="AddCourseClassificationVisible = true">新建课包</el-button> 
                </el-col>
            </el-row>
            <el-table :data="CourseClassificationList" v-loading="Loading" @row-click="ModifyCourseClassificationDialog" height="74vh">
                <el-table-column label="#" type="expand">
                    <template slot-scope="scope">
                        <div class="content_div">
                            <el-table :data="scope.row.courseIces" border>
                                <el-table-column label="名称" prop="name"></el-table-column>
                                <el-table-column label="价格" prop="price"></el-table-column>
                                <el-table-column label="描述" prop="description"></el-table-column>
                                <div slot="empty" class="empty_div">
                                    <el-empty description="暂无数据！" />
                                </div>
                            </el-table>
                            <span class="span_div">总计： {{scope.row.courseIces.length}} </span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="名称" prop="courseClassFicationName"></el-table-column>
                <el-table-column label="操作" prop="option" width="300">
                    <template slot-scope="scope">
                        <el-tooltip effect="dark" content="删除课包" placement="top" :enterable="false">
                            <el-button type="danger" icon="el-icon-delete" @click.stop="DeleteCourseClassification(scope.row)"></el-button>
                        </el-tooltip>
                    </template>
                </el-table-column>
                <div slot="empty" class="empty_div">
                    <el-empty description="暂无数据！" />
                </div>
            </el-table>
            <el-pagination
                @size-change="HandleSizeChange"
                @current-change="HandleCurrentChange"
                :current-page="QueryInfo.Start"
                :page-sizes="spreadCount"
                :page-size="QueryInfo.Count"
                layout="total, sizes, prev, pager, next, jumper"
                :total="CourseClassificationTotal">
            </el-pagination>

            <el-dialog title="新建课包" :visible.sync="AddCourseClassificationVisible" width="30%" :close-on-click-modal="false" @close="AddCourseClassificationDialogClose">
                <el-form ref="AddCourseClassificationFormRef" :model="AddCourseClassificationForm" :rules="AddCourseClassificationFormRules" 
                label-width="80px" v-loading="AdLoading">
                    <el-form-item label="名称" prop="CourseClassFicationName">
                        <el-input placeholder="请输入课包名称" v-model="AddCourseClassificationForm.CourseClassFicationName"></el-input>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="AddCourseClassificationDialogClose">取消</el-button>
                    <el-button type="primary" @click="AddCourseClassification" :disabled="AdLoading">确定</el-button>
                </span>
            </el-dialog>

            <el-dialog title="编辑课包" :visible.sync="ModifyCourseClassificationVisible" :close-on-click-modal="false" width="30%" @close="ModifyCourseClassificationDialogClose">
                <el-form ref="ModifyCourseClassificationFormRef" :model="ModifyCourseClassificationForm" :rules="ModifyCourseClassificationFormRules" 
                label-width="80px" v-loading="EdLoading">
                    <el-form-item label="名称" prop="CourseClassFicationName">
                        <el-input placeholder="请输入课包名称" v-model="ModifyCourseClassificationForm.CourseClassFicationName"></el-input>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="ModifyCourseClassificationDialogClose">取消</el-button>
                    <el-button type="primary" @click="ModifyCourseClassification" :disabled="EdLoading">确定</el-button>
                </span>
            </el-dialog>
        </el-card>
    </div>
</template>
<script>
import { PostCourseClassFicationInfo, GetQueryCourseClassFicationList, PutCourseClassFicationInfo, DeleteCourseClassFication } 
from '@/assets/js/api/courseclassficationapi' ;
import { SpreadCount } from '@/assets/js/systemvariable.js';
export default {
    data(){
        return{
            spreadCount:SpreadCount,
            QueryInfo:{
                Name:'',
                Start:0,
                Count:SpreadCount[0]
            },
            CourseClassificationList:[],
            CourseClassificationTotal:0,
            AddCourseClassificationVisible:false,
            AddCourseClassificationForm:{
                CourseClassFicationName:'',
            },
            AddCourseClassificationFormRules:{
                CourseClassFicationName: [
                    { required: true, message: '请输入课包名称', trigger: 'blur' }
                ]
            },
            ModifyCourseClassificationVisible:false,
            ModifyCourseClassificationForm:{
                ID:'',
                CourseClassFicationName:'',
            },
            ModifyCourseClassificationFormRules:{
                CourseClassFicationName: [
                    { required: true, message: '请输入课包名称', trigger: 'blur' }
                ]
            },
            Loading:false,
            AdLoading:false,
            EdLoading:false,
        }
    },
    created(){
        this.QueryCourseClassFicationList()
    },
    methods:{
        HandleSizeChange(count){
            this.QueryInfo.Count = count
            this.QueryCourseClassFicationList()
        },
        HandleCurrentChange(start){
            this.QueryInfo.Start = start
            this.QueryCourseClassFicationList()
        },
        AddCourseClassificationDialogClose(){
            this.$refs.AddCourseClassificationFormRef.resetFields();
            this.AddCourseClassificationVisible = false
        },
        async AddCourseClassification(){
            this.$refs.AddCourseClassificationFormRef.validate(valid =>{
                this.validret = valid
            })
            if(!this.validret){
                return
            }
            this.AdLoading = true
            var ret = await PostCourseClassFicationInfo(this.AddCourseClassificationForm)
            this.AdLoading = false
            if(ret == null){
                return this.$message.error('无法请求到服务器，请联系管理员');
            }
            if(ret.code == 1)
            {
                this.$message.success('新建课包成功');
                this.$refs.AddCourseClassificationFormRef.resetFields();
                this.AddCourseClassificationVisible = false
                this.QueryCourseClassFicationList()
            }
            else
            {
                this.$message.error(ret.message);
            }
        },
        initQueryCourseClassFicationList(){
            this.QueryInfo.Start = 1
            this.QueryInfo.Count = this.spreadCount[0]
            this.QueryCourseClassFicationList()
        },
        async QueryCourseClassFicationList(){
            this.Loading = true
            var ret = await GetQueryCourseClassFicationList(this.QueryInfo)
            if(ret == null){
                return this.$message.error('无法请求到服务器，请联系管理员');
            }
            if(ret.code == 1)
            {
                this.CourseClassificationList = ret.data.courseclassfications
                this.CourseClassificationTotal = ret.data.total
                this.Loading = false
            }
            else
            {
                this.$message.error(ret.message);
            } 
        },
        ModifyCourseClassificationDialog(courseclassificationinfo){
            this.ModifyCourseClassificationForm.ID = courseclassificationinfo.id
            this.ModifyCourseClassificationForm.CourseClassFicationName = courseclassificationinfo.courseClassFicationName
            this.ModifyCourseClassificationVisible = true
        },
        async DeleteCourseClassification(courseclassificationinfo){
            const confirmResult = await this.$confirm('此操作将删除课包，导致课包无法使用。 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).
            catch(err=>err);
            if(confirmResult !== 'confirm'){
                return this.$message.info('已取消删除')
            }
            this.Loading = true
            var ret = await DeleteCourseClassFication(courseclassificationinfo.id)
            this.Loading = false
            if(ret == null){
                return this.$message.error('无法请求到服务器，请联系管理员');
            }
            if(ret.code == 1)
            {
                this.$message.success('删除成功');
                this.QueryCourseClassFicationList()
            }
            else
            {
                this.$message.error(ret.message);
            }
        },
        ModifyCourseClassificationDialogClose(){
            this.$refs.ModifyCourseClassificationFormRef.resetFields();
            this.ModifyCourseClassificationVisible = false
        },
        async ModifyCourseClassification(){
            this.$refs.ModifyCourseClassificationFormRef.validate(valid =>{
                this.validret = valid
            })
            if(!this.validret){
                return
            }
            let it = this.CourseClassificationList.find(item => item.id == this.ModifyCourseClassificationForm.ID);
            if(it.courseClassFicationName == this.ModifyCourseClassificationForm.CourseClassFicationName){
                return this.$message.warning('数据没有修改，不需要提交');
            }
            this.EdLoading = true
            var ret = await PutCourseClassFicationInfo(this.ModifyCourseClassificationForm)
            this.EdLoading = false
            if(ret == null){
                return this.$message.error('无法请求到服务器，请联系管理员');
            }
            if(ret.code == 1)
            {
                this.$message.success('修改课包成功');
                this.$refs.ModifyCourseClassificationFormRef.resetFields();
                this.ModifyCourseClassificationVisible = false
                this.QueryCourseClassFicationList()
            }
            else
            {
                this.$message.error(ret.message);
            }
        }
    }
}
</script>

<style scoped lang="less">
.span_div{
    font-size: 15px;
}
.content_div{
    text-align: center;
}
</style>